import React from 'react'
import classNames from 'classnames'
import { number, string, bool, oneOf } from 'prop-types'
import Plx from 'react-plx'

import GreenTriangle from './GreenTriangle'
import YellowTriangle from './YellowTriangle'
import OrangeCircle from './OrangeCircle'
import PinkCircle from './PinkCircle'
import RedCircle from './RedCircle'

import styles from '../../theme/BBFCBackgroundShapes.module.scss'

const shapeMap = {
  U: <GreenTriangle />,
  PG: <YellowTriangle />,
  '12A': <OrangeCircle />,
  '12': <OrangeCircle />,
  '15': <PinkCircle />,
  '18': <RedCircle />
}

const Shape = ({ rating, size, startRotation, isRightSide, startY }) => {
  const parallaxData = [
    {
      start: 0,
      duration: 'body',
      properties: [
        {
          startValue: startY,
          endValue: startY + 3000,
          property: 'translateY'
        },
        {
          startValue: startRotation,
          endValue: startRotation + 180,
          property: 'rotate'
        }
      ]
    }
  ]

  return (
    <Plx
      parallaxData={parallaxData}
      className={classNames(
        styles.Shape,
        styles[size],
        isRightSide && styles.right
      )}
    >
      {shapeMap[rating]}
    </Plx>
  )
}

Shape.propTypes = {
  rating: string,
  size: oneOf(['small', 'medium', 'large']),
  startRotation: number,
  startY: number,
  isRightSide: bool
}

export default Shape
