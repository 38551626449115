import React from 'react'
import classNames from 'classnames'
import { number, string, oneOf } from 'prop-types'
import Plx from 'react-plx'

import GreenFish from './GreenFish'
import YellowFish from './YellowFish'
import OrangeFish from './OrangeFish'
import PinkFish from './PinkFish'
import RedFish from './RedFish'

import styles from '../../theme/CBBFCBackgroundShapes.module.scss'

const fishMap = {
  U: <GreenFish />,
  PG: <YellowFish />,
  '12A': <OrangeFish />,
  '12': <OrangeFish />,
  '15': <PinkFish />,
  '18': <RedFish />
}

const speedMap = {
  small: 3,
  medium: 2,
  large: 1
}

const Fish = ({ size, rating, startX, startY }) => {
  const parallaxData = [
    {
      start: 0,
      duration: 'body',
      properties: [
        {
          startValue: startX,
          endValue: startX + speedMap[size] * 1000,
          property: 'translateX'
        },
        {
          startValue: startY,
          endValue: startY + 3000,
          property: 'translateY'
        }
      ]
    }
  ]

  return (
    <Plx
      parallaxData={parallaxData}
      className={classNames(styles.Fish, styles[size])}
    >
      {fishMap[rating]}
    </Plx>
  )
}

Fish.propTypes = {
  size: oneOf(['small', 'medium', 'large']),
  rating: string,
  startX: number,
  startY: number
}

export default Fish
