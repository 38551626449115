import React from 'react'

const Fish = () => (
  <svg viewBox="0 0 178 146" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M924.752 37.123l786.202 1362.526c28.549 49.497-7.148 111.351-64.267 111.351H74.304c-57.12 0-92.794-61.854-64.267-111.351L796.239 37.123c28.57-49.497 99.942-49.497 128.513 0"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M178 73c0 40.316-32.719 73-73.078 73-40.36 0-73.078-32.684-73.078-73S64.563 0 104.922 0C145.282 0 178 32.684 178 73"
        fill="#FB4F93"
      />
      <path
        d="M42.29 75.535L3.787 96.52c-1.397.76-3.145-.19-3.145-1.715V52.839c0-1.525 1.748-2.479 3.145-1.717L42.29 72.105c1.4.763 1.4 2.669 0 3.43"
        fill="#FB4F93"
      />
      <path
        d="M140.06 50.834c-5.875.359-10.947-4.123-11.306-9.991-.36-5.868 4.128-10.936 10.003-11.295s10.946 4.123 11.305 9.993c.36 5.868-4.127 10.934-10.002 11.293"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default Fish
