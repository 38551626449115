import React from 'react'

const Fish = () => (
  <svg viewBox="0 0 113 104">
    <g fill="none" fillRule="evenodd">
      <path
        d="M110.752 55.683l-81.988 47.269c-2.978 1.717-6.7-.43-6.7-3.862V4.55c0-3.435 3.722-5.582 6.7-3.864l81.988 47.27c2.978 1.718 2.978 6.01 0 7.727"
        fill="#0AC700"
      />
      <path
        d="M33.4 54.09L2.524 71.817C1.403 72.46 0 71.657 0 70.367V34.916c0-1.287 1.402-2.093 2.523-1.45L33.4 51.193a1.668 1.668 0 0 1 0 2.898"
        fill="#0AC700"
      />
      <path
        d="M69.935 44.03a5.716 5.716 0 0 0 2.495-7.676 5.717 5.717 0 0 0-7.68-2.493 5.714 5.714 0 0 0-2.493 7.675 5.714 5.714 0 0 0 7.678 2.495"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default Fish
