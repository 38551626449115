import React from 'react'
import PropTypes from 'prop-types'
import SubNavigation from '@/component/Common/SubNavigation'

import getRatings from './get-ratings'

const RatingsSubNavigation = ({ theme }) => {
  const subNavProps = getRatings(theme)
  return <SubNavigation hasLine {...subNavProps} isTeal />
}

RatingsSubNavigation.propTypes = {
  theme: PropTypes.string
}

export default RatingsSubNavigation
