import React from 'react'

const Fish = () => (
  <svg viewBox="0 0 209 170">
    <g fill="none" fillRule="evenodd">
      <path
        d="M209 85c0 46.944-38.632 85-86.284 85-47.653 0-86.285-38.056-86.285-85S75.063 0 122.716 0C170.368 0 209 38.056 209 85"
        fill="#FF7D13"
      />
      <path
        d="M48.627 87.952L3.672 112.385C2.04 113.27 0 112.163 0 110.388V61.525c0-1.776 2.04-2.886 3.672-2l44.955 24.433c1.635.889 1.635 3.107 0 3.994"
        fill="#FF7D13"
      />
      <path
        d="M164.701 59.19c-6.86.418-12.781-4.8-13.2-11.633-.42-6.833 4.82-12.734 11.679-13.152 6.86-.417 12.78 4.801 13.2 11.636.42 6.833-4.82 12.731-11.679 13.149m-32.596 0c-6.86.418-12.781-4.8-13.2-11.633-.42-6.833 4.819-12.734 11.678-13.152 6.86-.417 12.781 4.801 13.2 11.636.42 6.833-4.819 12.731-11.678 13.149"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default Fish
