import ratingsSubNavigation from './ratings'

const cbbfcRatings = ['U', 'PG', '12', '12A']

const getRatings = (theme) => {
  const nav = ratingsSubNavigation()
  if (theme === 'cbbfc') {
    nav.links = nav.links.filter((l) => {
      return cbbfcRatings.includes(l.rating)
    })
  }

  return nav
}

export default getRatings
