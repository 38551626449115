import React from 'react'
import { number, oneOf, arrayOf } from 'prop-types'
import { displayRatings } from '../../../../../../data/ratings.json'
import { useRatingContext } from '@/component/Context/RatingContext'
import styles from '../theme/CBBFCBackgroundShapes.module.scss'

import Fish from './fish/Fish'

const BackgroundShapes = ({
  viewportHeight,
  viewportWidth,
  numIterations,
  sizes
}) => {
  const iterationArray = Array(numIterations).fill('')
  const rating = useRatingContext()
  return (
    <div className={styles.ShapesWrapper}>
      {typeof window !== 'undefined' &&
        iterationArray.map((item, i) =>
          Array(2)
            .fill('')
            .map((fish, j) => (
              <Fish
                key={`Fish-${i}-${j}`}
                startX={viewportWidth * -0.2 * i + viewportWidth * j * 0.6}
                startY={viewportHeight * 0.5 * (i + 1) + Math.random() * 100}
                rating={
                  rating ||
                  displayRatings[
                    Math.floor(Math.random() * displayRatings.length)
                  ]
                }
                size={sizes[Math.floor(Math.random() * sizes.length)]}
              />
            ))
        )}
    </div>
  )
}

BackgroundShapes.propTypes = {
  viewportHeight: number,
  viewportWidth: number,
  numIterations: number,
  sizes: arrayOf(oneOf(['small', 'medium', 'large']))
}

export default BackgroundShapes
