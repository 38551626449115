import React from 'react'
import { useThemeContext } from '@/component/Context/ThemeContext'

import BBFCBackgroundShapes from './component/BBFCBackgroundShapes'
import CBBFCBackgroundShapes from './component/CBBFCBackgroundShapes'

const BackgroundShapes = () => {
  const theme = useThemeContext()
  const [viewportHeight, setViewportHeight] = React.useState(0)
  const [viewportWidth, setViewportWidth] = React.useState(0)
  const [numIterations, setNumIterations] = React.useState(10)
  const sizes = ['small', 'medium', 'large']

  React.useEffect(() => {
    setViewportHeight(window.innerHeight)
    setViewportWidth(window.innerWidth)
    setNumIterations(
      Math.floor(document.body.clientHeight / window.innerHeight)
    )
  }, [])

  const propsToPass = {
    viewportHeight,
    numIterations,
    sizes
  }

  const componentMap = {
    bbfc: <BBFCBackgroundShapes {...propsToPass} />,
    cbbfc: (
      <CBBFCBackgroundShapes viewportWidth={viewportWidth} {...propsToPass} />
    )
  }
  return componentMap[theme]
}

export default BackgroundShapes
