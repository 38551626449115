import React from 'react'

const Shape = () => (
  <svg viewBox="0 0 172 151" xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M92.421 3.71l78.575 136.162c2.853 4.947-.714 11.128-6.423 11.128H7.426c-5.709 0-9.274-6.181-6.423-11.128L79.578 3.71c2.855-4.947 9.988-4.947 12.843 0"
      fill="#FBAD00"
    />
  </svg>
)

export default Shape
