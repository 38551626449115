const ratingsSubNavigation = () => ({
  title: 'Ratings at a glance',
  links: [
    {
      name: 'Suitable for all',
      as: '/rating/u',
      to: '/rating/[rating]',
      rating: 'U'
    },
    {
      name: 'Parental guidance',
      as: '/rating/pg',
      to: '/rating/[rating]',
      rating: 'PG'
    },
    {
      name: 'Cinema release suitable for 12 years and over',
      as: '/rating/12',
      to: '/rating/[rating]',
      rating: '12A'
    },
    {
      name: 'Video release suitable for 12 years and over',
      as: '/rating/12',
      to: '/rating/[rating]',
      rating: '12'
    },
    {
      name: 'Suitable only for 15 years and over',
      as: '/rating/15',
      to: '/rating/[rating]',
      rating: '15'
    },
    {
      name: 'Suitable only for adults',
      as: '/rating/18',
      to: '/rating/18',
      rating: '18'
    },
    {
      name: 'Adults works for licensed premises only',
      as: '/rating/r18',
      to: '/rating/r18',
      rating: 'R18'
    }
  ]
})

export default ratingsSubNavigation
