import React from 'react'

const Fish = () => (
  <svg viewBox="0 0 78 72">
    <g fill="none" fillRule="evenodd">
      <path
        d="M76.449 38.868L19.855 71.281c-2.056 1.178-4.624-.294-4.624-2.648V3.805c0-2.355 2.568-3.827 4.624-2.65L76.449 33.57c2.055 1.178 2.055 4.12 0 5.298"
        fill="#FBAD00"
      />
      <path
        d="M23.055 37.776L1.741 49.931C.968 50.373 0 49.821 0 48.938v-24.31c0-.883.968-1.436 1.741-.994l21.314 12.155a1.14 1.14 0 0 1 0 1.987"
        fill="#FBAD00"
      />
      <path
        d="M48.274 30.878a3.907 3.907 0 0 0 1.722-5.264c-.987-1.923-3.364-2.69-5.3-1.71a3.906 3.906 0 0 0-1.722 5.263c.986 1.924 3.364 2.69 5.3 1.711"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default Fish
