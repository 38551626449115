import React from 'react'
import { number, oneOf, arrayOf } from 'prop-types'
import { useRatingContext } from '@/component/Context/RatingContext'

import Shape from './shapes/Shape'
import RatingColor from '@/component/Common/RatingColor'

import styles from '../theme/BBFCBackgroundShapes.module.scss'

import { displayRatings } from '../../../../../../data/ratings.json'

const BackgroundShapes = ({ viewportHeight, numIterations, sizes }) => {
  const rating = useRatingContext()

  return (
    <div className={styles.ShapesWrapper}>
      {Array(numIterations)
        .fill('')
        .map((item, i) => (
          <RatingColor key={`${i}${Math.random()}`} property="fill">
            <Shape
              startRotation={Math.random() * 360}
              startY={viewportHeight * (i + 0.4)}
              rating={
                rating ||
                displayRatings[
                  Math.floor(Math.random() * displayRatings.length)
                ]
              }
              size={sizes[Math.floor(Math.random() * sizes.length)]}
            />
            <Shape
              startRotation={Math.random() * 360}
              startY={viewportHeight * (i + 0.6)}
              isRightSide
              rating={
                rating ||
                displayRatings[
                  Math.floor(Math.random() * displayRatings.length)
                ]
              }
              size={sizes[Math.floor(Math.random() * sizes.length)]}
            />
          </RatingColor>
        ))}
    </div>
  )
}

BackgroundShapes.propTypes = {
  viewportHeight: number,
  numIterations: number,
  sizes: arrayOf(oneOf(['small', 'medium', 'large']))
}

export default BackgroundShapes
