import React from 'react'

const Fish = () => (
  <svg viewBox="0 0 69 64" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path id="a" d="M.031.075h9.194V7.91H.03z" />
      <path id="c" d="M0 .084h18.447V20.82H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M66.155 32c0 16.084-12.74 29.124-28.454 29.124S9.247 48.084 9.247 32c0-16.085 12.74-29.124 28.454-29.124 15.715 0 28.454 13.04 28.454 29.124"
        fill="#DC0A0A"
      />
      <path
        d="M58.157 27.987c-1.34-.294-2.196-1.637-1.904-2.993.29-1.356 1.62-2.22 2.96-1.925 1.342.294 2.197 1.637 1.905 2.993-.29 1.356-1.62 2.22-2.96 1.925"
        fill="#FFF"
      />
      <path
        d="M42.269 7.909l-8.022.001a.807.807 0 0 1-.704-1.21L37.206.404a.816.816 0 0 1 1.407 0l4.012 6.893a.408.408 0 0 1-.356.613"
        fill="#DC0A0A"
      />
      <g transform="translate(32.722 56.09)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M.44.076L8.417.075a.8.8 0 0 1 .7 1.198L5.473 7.51a.812.812 0 0 1-1.399 0L.087.683A.404.404 0 0 1 .44.076"
          fill="#DC0A0A"
          mask="url(#b)"
        />
      </g>
      <path
        d="M24.063 12.116l-5.778 5.625c-.45.438-1.22.238-1.385-.36l-1.933-7.017c-.165-.6.398-1.148 1.014-.987l7.894 2.057c.311.081.416.46.188.682m26.565 39.768l5.778-5.625c.45-.439 1.22-.238 1.385.36l1.932 7.018c.166.599-.397 1.147-1.013.986l-7.894-2.057a.404.404 0 0 1-.188-.682"
        fill="#DC0A0A"
      />
      <g transform="translate(0 21.573)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          d="M.003 19.924L0 .98C0 .292.755-.14 1.36.205l16.634 9.47a.888.888 0 0 1 0 1.549L1.362 20.698c-.604.344-1.36-.085-1.36-.774"
          fill="#DC0A0A"
          mask="url(#d)"
        />
      </g>
      <path
        d="M61.176 36.258v-8.11c-.001-.632.664-1.027 1.196-.711l6.23 3.703a.834.834 0 0 1 0 1.422l-6.82 4.056c-.27.16-.606-.04-.606-.36m-43.5 9.17l5.564 5.84c.434.456.236 1.234-.357 1.4l-6.94 1.954c-.593.167-1.136-.402-.977-1.024l2.036-7.98c.08-.315.455-.42.674-.19m39.339-26.856l-5.564-5.84c-.434-.456-.236-1.233.356-1.4l6.942-1.954c.592-.167 1.134.402.976 1.024l-2.036 7.98c-.08.315-.455.42-.674.19"
        fill="#DC0A0A"
      />
    </g>
  </svg>
)

export default Fish
